import { bindable } from 'aurelia-framework';
import { Calendar } from 'services/calendar';
import { MemberConfig } from 'services/member-config';
import { Notifier } from 'common/ui';

export class MyBookingCalendarButton {
    static inject = [Calendar, MemberConfig, Notifier];
    _calendar;
    _memberConfig;
    _notifier;

    @bindable bookName;
    @bindable bookEmail;
    @bindable bookPhone;
    @bindable bookAddress;
    @bindable bookDob;
    @bindable buttonClass = 'btn btn-icon btn-outline me-3';
    @bindable buttonText;
    @bindable iconClass = 'fs-3 fa-duotone fa-calendar-days';
    @bindable settingKey;
    @bindable meSize = '3';

	constructor(calendar, memberConfig, notifier) {
        this._calendar = calendar;
        this._memberConfig = memberConfig;
        this._notifier = notifier;
	}

    async openScheduler() {
        try {
            if (!this._calendarSchedulerPageSlug && this.settingKey) this._calendarSchedulerPageSlug = await this._memberConfig.value(this.settingKey);
            if (!this._calendarSchedulerPageSlug) {
                this._notifier.info('lead-scheduler-not-set-up', undefined, true);
                return;
            }
            this._calendar.openSchedulerPage(this._calendarSchedulerPageSlug, this.bookName, this.bookEmail, this.bookPhone, this.bookAddress, this.bookDob, 5000);
        } catch (err) {
            console.log(err);
        }
    }
}
