import { bindable } from 'aurelia-framework';

export class CardToolbar {
    static inject = [Element];
    _element;

    @bindable icon = 'fa-duotone fa-square-ellipsis';
    @bindable actions = [];

    constructor(element) {
        this._element = element;
    }

    actionClicked(action) {
        this._element.dispatchEvent(new CustomEvent('action', { bubbles: true, detail: action }));
        this.closeMenu = new Date().getTime();
    }
}