import { bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { Bonuses } from 'services/bonuses';
import { TimePeriodNavigatorBase } from 'resources/base-classes/time-period-navigator-base';
import { WIDGET, REPORT_TIME_PERIOD } from 'common/constants';

export class DownlineBonusProgress extends TimePeriodNavigatorBase {
    static inject = [Router, EventAggregator, Security, Bonuses];
    _router;
    _security;
    _bonuses;

    @bindable view;
    @bindable sync;
    @bindable priorMonthsAllowed;

    timePeriod = REPORT_TIME_PERIOD.MTD;

    constructor(router, ea, security, bonuses) {
        super('downline-bonus-progress', ea);
        this._router = router;
        this._security = security;
        this._bonuses = bonuses;
    }

    attached() {
        this._isAttached = true;
        this._attached();
        this._loadData();
    }

    viewChanged() {
        if (!this._isAttached) return;
        this._loadData();
    }

    async _loadData(fromSync = false) {
        if (!this.view) return;
        try {
            this.loading = true;
            this.initializeTimePeriodNavigation(fromSync);

            this.progress = await this._bonuses.downlinePersonal(this._security.authenticatedMemberId, this.view, this.asOfDate.toISOString(true));

            this.currentPeriod = this.progress.bonusPeriod;
            this.setTimePeriodNavigationDisplays();
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }
}
