import { bindable } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { DialogService } from 'aurelia-dialog';
import { EditToDo } from 'resources/elements/to-dos/dialogs/edit-to-do';
PLATFORM.moduleName('resources/elements/to-dos/dialogs/edit-to-do');


export class CardReminderButton {
    static inject = [DialogService];
    _dialogService;

    @bindable aboutId;
    @bindable aboutType;
    @bindable aboutName;
    @bindable meSize = '3';
    @bindable outline = true;

	constructor(dialogService) {
        this._dialogService = dialogService;
	}

    addToDo() {
        const model = { type: this.aboutType, aboutId: this.aboutId, aboutName: this.aboutName, toDo: undefined };
        this._dialogService.open({ viewModel: EditToDo, model, ignoreTransitions: true });
    }
}
