import { bindable } from 'aurelia-framework';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';

export class MonthlyBonusProgress {
    @bindable data;
    @bindable showTitle = true;
    @bindable requiresPlacement = true;
    @bindable formatGoalAs = 'money';
    @bindable pad = 5000;
    chartEl;

    constructor() {
        Chart.register(annotationPlugin);
    }

    dataChanged() {
        if (!this.data) return;
        this._loadChart();
    }

    attached() {
        this._loadChart();
    }

    _loadChart() {
        if (!this.data || !this.chartEl) return;

        let max = 0;
        const datasets = [];
        const annotations = {};
        let annotationIndex = 1;
        let bgColorQualified;
        let bgColorNotQualified;
        if (this.requiresPlacement) {
            bgColorQualified = this.data.placementReached ? 'Green' : 'Red';
            bgColorNotQualified = this.data.placementReached ? 'rgba(0, 255, 0, 0.2)' : 'rgba(255, 0, 0, 0.2)';
        } else {
            bgColorQualified = 'Green';
            bgColorNotQualified = 'rgba(0, 255, 0, 0.2)';
        }
        const borderColor = bgColorQualified;
        this.data.goals.forEach(g => {
            max = g.minimumBonusCredit + this.pad;
            let formattedLabel = `$${g.bonus}`;
            switch (this.formatGoalAs) {
                case 'percentage': formattedLabel = `${g.bonusPercentage}%`; break;
                default: break;
            }
            datasets.push({
                label: '',
                data: [g.progress],
                backgroundColor: [g.qualifiedForBonus ? bgColorQualified : bgColorNotQualified],
                borderColor: [borderColor],
                borderWidth: 1,
            });
            annotations[`goal${annotationIndex++}`] = {
                type: 'line',
                scaleID: 'x',
                value: g.minimumBonusCredit,
                borderWidth: 2,
                borderColor: 'Black',
                label: {
                    content: formattedLabel,
                    enabled: true,
                },
            };
        });

        if (this._chart) {
            this._chart.data.datasets = datasets;
            this._chart.options.scales.x.max = max;
            this._chart.options.plugins.annotation.annotations = annotations;
            this._chart.update();
            return;
        }

        const config = {
            type: 'bar',
            data: {
                labels: [''],
                datasets,
            },
            options: {
                indexAxis: 'y',
                responsive: true,
                interaction: {
                    intersects: false,
                },
                scales: {
                    y: {
                        beginAtZero: true,
                        stacked: true,
                    },
                    x: {
                        max,
                        ticks: {
                            callback: function(val, index) {
                                return `$${this.getLabelForValue(val)}`;
                            },
                        },
                    },
                },
                plugins: {
                    legend: { display: false },
                    tooltip: {
                        callbacks: {
                            label: (ctx) => {
                                return `$${ctx.formattedValue}`;
                            },
                        },
                    },
                    annotation: {
                        annotations,
                    },
                },
            }
        };

        this._chart = new Chart(this.chartEl, config);
    }
}
